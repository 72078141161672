import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification/notification.service';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            // handle client-side error
            console.log('this is client side error');
            errorMsg = `Error: ${error.error.message}`;
          }
          else {
            // handle server-side error
            console.log('this is server side error');
            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
            // handle token expiration
            const access_token = this.authService.getAccesToken();
            if (access_token && error.status === 401) {
              console.log('The token has expired or is invalid');
              this.notificationService.errorMessage('The token has expired or is invalid');
              localStorage.clear();
              this.router.navigate(['auth']);
            }
          }
          console.log(errorMsg);
          return throwError(error);
        })
      );
  }
}

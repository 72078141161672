import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { map } from 'rxjs/operators';
import { UserService } from '../../services/user/user.service';
import { User } from 'src/app/shared/interfaces/user/user';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  user!: User;

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.thereIsASavedToken();
  }

  goToPrivate() {
    this.router.navigate(['']);
  }

  thereIsASavedToken() {
    const access_token = this.authService.getAccesToken();
    if (access_token) {
      this.goToPrivate();
      return false;
    }
    else {
      return true;
    }
  }


}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RegisterCredentials } from '../../../shared/interfaces/auth/register-credentials/register-credentials';
import { LoginCredentials } from '../../../shared/interfaces/auth/login-credentials/login-credentials';
import { User } from 'src/app/shared/interfaces/user/user';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authUrl!: string;
  registerUrl!: string;
  loginUrl!: string;
  userDataUrl!: string;

  constructor(private httpClient: HttpClient) {
    this.authUrl = 'auth';
    this.registerUrl = 'register';
    this.loginUrl = 'login';
    this.userDataUrl = 'user-data';
  }

  register(credentials: RegisterCredentials) {
    return this.httpClient.post(`${environment.apiUrl}${this.authUrl}/${this.registerUrl}`, credentials).pipe(
      map((res: any) => {
        console.log(res.access_token);
        localStorage.setItem('access_token', res.access_token);
        return res;
      })
    );

  }

  login(credentials: LoginCredentials) {
    return this.httpClient.post(`${environment.apiUrl}${this.authUrl}/${this.loginUrl}`, credentials).pipe(
      map((res: any) => {
        //console.log(res.access_token);
        localStorage.setItem('access_token', res.access_token);
        return res;
      })
    );
  }

  getAccesToken() {
    return localStorage.getItem('access_token');
  }

  getUserLoggedData() {
    return this.httpClient.get<User>(`${environment.apiUrl}${this.authUrl}/${this.userDataUrl}`);
  }

}
